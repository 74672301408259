import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HTMLReactParser from "html-react-parser"
import { getValueD9, getDescription } from "../utils"
import Actu from "../components/Actus"
import SocialSharing from "../components/SocialSharing"
const IndexPage = ({ data, ...props }) => {
  //fetch on useEffect
  const [messages, setMessages] = useState(false)
  useEffect(() => {
    console.log(data.site.siteMetadata, "siteMetadata")
    const fetchData = async () => {
      const result = await fetch(
        `${data.site.siteMetadata.drupalUrl}/message-sous-sites?gid=${data.groupSousSite.drupal_internal__id}&api-key=${data.site.siteMetadata.apikey}`
      )
      const json = await result.json()
      console.log(json)
      if (json.length > 0) setMessages(json)
    }
    fetchData()
  }, [])

  return (
    <Layout
      sliderData={data.groupSousSite.relationships.field_slider}
      {...props}
      description={getDescription(getValueD9(data.groupSousSite, "field_body.processed", ""))}
    >
      <SEO title={getValueD9(data.groupSousSite, "title", "")} />
      <div className="">
        {messages !== false && (
          <>
            {messages.map((message, index) => {
              console.log(message, "message")
              var typemessage =
                getValueD9(message, "field_message_type.0.value", "") === "info"
                  ? "uk-alert-primary"
                  : "uk-alert-" + getValueD9(message, "field_message_type.0.value", "")
              return (
                <div className={typemessage} uk-alert="" key={index}>
                  <h3>{HTMLReactParser(getValueD9(message, "title.0.value", ""))}</h3>
                  <a className="uk-alert-close" uk-close=""></a>
                  {HTMLReactParser(getValueD9(message, "body.0.processed", ""))}
                </div>
              )
            })}
          </>
        )}

        <div className="uk-section uk-padding uk-background-default">
          {HTMLReactParser(getValueD9(data.groupSousSite, "field_body.processed", ""))}
          <SocialSharing />
        </div>
        <div className="uk-section">
          <h2>Nos actualités</h2>
          <Actu limit={3} buttonAfter={true} />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
export const query = graphql`
  {
    site {
      siteMetadata {
        drupalUrl
        apikey
      }
    }
    groupSousSite {
      id
      label
      field_email
      drupal_internal__id
      field_color {
        color
      }
      field_address_text {
        value
        processed
        format
      }
      field_body {
        value
        format
        processed
      }
      relationships {
        field_slider {
          field_subtitle
          field_title
          relationships {
            field_cover {
              relationships {
                field_media_image {
                  placeholderUrl
                  url
                  uri {
                    url
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
