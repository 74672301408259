import React, { useEffect, useState } from "react"
const SocialSharing = () => {
  const [url, setUrl] = useState("")
  useEffect(() => {
    if (typeof window !== "undefined") {
      setUrl(window.location.href)
    }
  }, [])
  return url ? (
    <div id="social-share" className="uk-margin uk-flex uk-flex-middle">
      <div className="uk-margin-small-right">Partager cette page sur :</div>
      <a
        href={"http://facebook.com/sharer.php?u=" + url}
        className="uk-icon-button uk-margin-small-right facebook"
        target="_blank"
      >
        <span uk-icon="icon: facebook"></span>
      </a>
      <a
        href={"mailto:?Subject=&amp;Body=J'ai lu un article qui devrait vous intéresser : " + url}
        className="uk-icon-button uk-margin-small-right email"
        title="Partager par Email"
        target="_blank"
      >
        <span uk-icon="icon: mail"></span>
      </a>
    </div>
  ) : null
}

export default SocialSharing
