import React from "react"
import { Link } from "gatsby"
import { cleanPath } from "../../utils"
const Card = ({ Title, SubTitle, Content, Img, link, linkLabel }) => {
  linkLabel = linkLabel || "En savoir plus"
  return (
    <div
      className="uk-card uk-card-border-left  uk-background-default uk-grid-collapse uk-margin uk-grid"
      uk-grid=""
    >
      <div className="uk-flex-last@s uk-card-media-right uk-cover-container uk-width-1-3@s">
        <img src={Img} alt={Title} uk-cover="" />
        <canvas width="600" height="400"></canvas>
      </div>
      <div className="uk-width-2-3@s ">
        <div className="uk-card-body">
          <h3 className="uk-card-title uk-text-normal">{Title}</h3>
          <p className="uk-text-meta uk-margin-remove-top">{SubTitle}</p>
          {Content}
          <Link className="uk-button uk-button-link" to={cleanPath(link)}>
            {linkLabel} <span uk-icon="chevron-right"></span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Card
