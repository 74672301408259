import React from "react"
import { getValueD9, renameImage, limitText, sort } from "../utils"
import Card from "./UI/Card"
import moment from "moment"
import { useStaticQuery, graphql, Link } from "gatsby"
const Actus = ({ limit, buttonAfter }) => {
  limit = limit || false
  buttonAfter = buttonAfter || false
  const data = useStaticQuery(graphql`
    {
      groupSousSite(
        relationships: {
          group_content__sous_site_group_node_ss_actu: {
            elemMatch: { relationships: { entity_id: { status: { eq: true } } } }
          }
        }
      ) {
        id
        label
        relationships {
          group_content__sous_site_group_node_ss_actu {
            relationships {
              entity_id {
                path {
                  alias
                }
                body {
                  processed
                  summary
                }
                published_at
                title
                relationships {
                  field_cover {
                    relationships {
                      field_media_image {
                        url
                        uri {
                          url
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const items = getValueD9(
    data,
    "groupSousSite.relationships.group_content__sous_site_group_node_ss_actu",
    []
  )
  if (items.length > 0) {
    return (
      <>
        {sort(items, "relationships.entity_id.published_at", []).map((node, i) => {
          return limit && i >= limit ? null : (
            <Card
              key={i}
              Title={getValueD9(node, "relationships.entity_id.title", "")}
              Content={
                <p>
                  {limitText(
                    getValueD9(node, "relationships.entity_id.body.processed", "").replace(
                      /&nbsp;/g,
                      ""
                    ),
                    60
                  )}
                  ...
                </p>
              }
              Img={renameImage(
                getValueD9(
                  node,
                  "relationships.entity_id.relationships.field_cover.relationships.field_media_image.uri.value",
                  false
                ),
                null,
                "square_event_home"
              )}
              SubTitle={
                "Posté le " +
                moment(getValueD9(node, "relationships.entity_id.published_at", "")).format(
                  "DD/MM/YYYY"
                )
              }
              link={getValueD9(node, "relationships.entity_id.path.alias", "/")}
            />
          )
        })}
        {buttonAfter && (
          <div className="uk-text-center">
            <Link to="/actualites" className="uk-button uk-button-primary uk-button-outline">
              Voir toutes les actualités
            </Link>
          </div>
        )}
      </>
    )
  } else {
    return <p>Pas d'actualités disponibles</p>
  }
}

export default Actus
